<template>
  <main role="main" class="page-content">
    <div class="subheader hidden-lg-up d-flex">
      <portal to="ariane">
        <h1 class="subheader-title text-info">
          <!-- <i class="fal fa-list text-info m-2"></i> Mes tickets -->
          <!-- <small>Liste des tickets format carte</small> -->
          <MultipleListCreate></MultipleListCreate>
        </h1>
      </portal>
    </div>

    <div v-if="!loading">
      <div class="w-100 bg-white rounded">
        <PostsFilters @filterpost="filterpost" />
      </div>
      <MapDashboard :posts="postsWithLocation" class="mt-5" :key="'map-dashboard-' + postsWithLocation.length"></MapDashboard>
    </div>
  </main>
</template>

<script>
import MultipleListCreate from '@/components/UI/MultipleListCreate'
import MapDashboard from '@/components/Model/Map/MapDashboard'
import PostsFilters from '@/components/Model/Post/PostsFilters'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'TicketMap',
  components: {
    MapDashboard,
    MultipleListCreate,
    PostsFilters,
  },
  data: function () {
    return {
      loading: true,
      postconfig: {},
      currentFilter: {},
    }
  },
  created() {
    this.configfilter()
    let postsPromise = this.getPostsLocation(this.currentFilter)
    let postsFiltersPromise = this.getPostsFilters()
    Promise.all([postsPromise, postsFiltersPromise]).then(() => {
      this.loading = false
    })
  },
  computed: {
    config: {
      get() {
        return this.$store.state.post.postsConfig
      },
      set(value) {
        this.$store.commit('post/setPostsConfig', value)
      },
    },
    postsWithLocation() {
      return this.posts ? this.posts.data.filter((post) => post.location) : []
    },
    ...mapState('post', {
      posts: (state) => state.myPosts,
    }),
  },
  methods: {
    ...mapActions('post', ['getPostsLocation', 'getPostsFilters']),
    configfilter(search = '') {
     if(search)
        this.currentFilter.search = search;
      this.currentFilter.include = 'answers,medias,form'
      this.currentFilter.filters = this.config.filters
      this.currentFilter.filters.with_location = ''
      this.currentFilter.per_page = ''
      this.currentFilter.page = ''
    },
    filterpost(search = '') {
      this.configfilter(search)
      this.getPostsLocation(this.currentFilter).then(() => {
        this.loading = false
      })
    },
  },
  mounted() {},
}
</script>
